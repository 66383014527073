import { useContext, mergeProps, splitProps } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { twMerge } from '@troon/tailwind-preset/merge';
import { useFocusable } from '../providers/focusable';
import { InputContext, FieldErrors, Field } from './field';
import { FormContext, disableAutocomplete } from './form';
import type { FieldProps } from './field';
import type { JSX } from 'solid-js';

type InputProps = Omit<JSX.InputHTMLAttributes<HTMLInputElement>, 'aria-required' | 'prefix' | 'suffix'> & {
	multiline?: never;
};
type TextareaProps = Omit<JSX.TextareaHTMLAttributes<HTMLTextAreaElement>, 'aria-required' | 'prefix' | 'suffix'> & {
	multiline: true;
};

type Props = (InputProps | TextareaProps) & {
	prefixElement?: JSX.Element;
	suffixElement?: JSX.Element;
};

export function Input(inputProps: Props) {
	const { data } = useContext(FormContext);
	const [context] = useContext(InputContext);
	const focusable = useFocusable<Props>();
	const [custom, props] = splitProps(mergeProps(context, focusable, inputProps), [
		'multiline',
		'prefixElement',
		'suffixElement',
	]);

	return (
		<div class="relative size-full">
			<div class="pointer-events-none absolute inset-y-2 left-2 flex flex-row items-center">{custom.prefixElement}</div>
			{/* @ts-expect-error */}
			<Dynamic
				component={custom.multiline ? 'textarea' : 'input'}
				{...props}
				autocapitalize={props.autocapitalize ?? (props.inputMode === 'email' ? 'none' : undefined)}
				readonly={data.pending || props.readonly}
				aria-readonly={data.pending || props.readonly}
				aria-required={props.required}
				onFocus={(event: Event) => {
					// @ts-ignore TODO: make an elegant way to merge event handlers
					focusable.onFocus && focusable.onFocus(event);
					// @ts-ignore TODO: make an elegant way to merge event handlers
					inputProps.onFocus && inputProps.onFocus(event);
				}}
				{...disableAutocomplete(props)}
				class={twMerge(
					'size-full min-w-min rounded border border-solid border-neutral bg-white px-4 py-3 outline-none transition-all duration-200 focus-visible:ring-1 focus-visible:ring-brand-700 aria-invalid:border-red-600 aria-readonly:bg-neutral-100 aria-readonly:text-neutral-700',
					props.class,
					// @ts-ignore
					props.type === 'date' &&
						'size-full min-w-full calendar-picker-indicator:absolute calendar-picker-indicator:inset-0 calendar-picker-indicator:z-0 calendar-picker-indicator:size-full calendar-picker-indicator:opacity-0 date-and-time-value:block date-and-time-value:w-full date-and-time-value:ps-6 date-and-time-value:text-start',
				)}
			/>
			<div class="pointer-events-none absolute inset-y-2 right-2 flex flex-row items-center">
				{custom.suffixElement}
			</div>
		</div>
	);
}

export function TextField(props: FieldProps) {
	return (
		<Field {...props} class={twMerge('flex flex-col gap-1', props.class)}>
			{props.children}
			<FieldErrors />
		</Field>
	);
}
